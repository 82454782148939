import React from "react";
import Challenge from "./challenge";
import { Timer } from "./timer";

const Card = ({ text }) => {
  const { useState, useRef, useEffect } = React;
  const [key] = useState(Math.random());
  const [captcha, setCaptcha] = useState({});
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(false);

  const [isReset, setIsReset] = useState(false);
  const [sliderStatus, setSliderStatus] = useState("");
  const [failed, setFailed] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [countdown, setCountdown] = useState();
  const [expiredDate, setExpiredDate] = useState();

  const closeCaptcha = () =>
    window.RecaptchaFlutterChannel?.postMessage(
      JSON.stringify({ action: "close", data: null })
    );

  const refreshCaptcha = () => {
    if (!blocked && !loading) {
      onResetChallenge(undefined, true);
      window.RecaptchaFlutterChannel?.postMessage(
        JSON.stringify({ action: "refresh", data: null })
      );
    }
  };

  const completeCaptcha = (response, trail, left) => {
    const data = {
      action: "completeCaptcha",
      data: {
        answer: response,
        trail: trail,
        left: left,
      },
    };

    window?.RecaptchaFlutterChannel.postMessage(JSON.stringify(data));
  };

  const onResetChallenge = (reset, isFullReset) => {
    if (reset != undefined) {
      setIsReset(reset);
    }

    if (isFullReset == true) {
      setFailed(false);
      setBlocked(false);
    }

    setCaptcha({});
    setLoading(true);
    setCountdown(undefined);
    setExpiredDate(undefined);
    setSliderStatus("init");
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (JSON.stringify(captcha) === "{}") {
      if (sliderStatus == "blocked") {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [captcha]);

  const onSetSliderStatus = (status) => {
    if (status != sliderStatus) {
      switch (status) {
        case "failure":
          setFailed(true);
          break;
        case "blocked":
          setBlocked(true);
          break;
        case "init":
          setBlocked(false);
          break;
        case "success":
        default:
          setBlocked(false);
          setFailed(false);
          break;
      }

      setSliderStatus(status);
    }
  };

  const onLockSliderCaptcha = (countdown, expiredDateTime) => {
    setCountdown(countdown);
    setExpiredDate(expiredDateTime);
    setCaptcha({});
  };

  const onTimeout = () => {
    onResetChallenge(undefined, true);
    window.RecaptchaFlutterChannel?.postMessage(
      JSON.stringify({ action: "unblock", data: null })
    );
  };

  useEffect(() => {
    const handleMessageFromFlutter = (event) => {
      try {
        if (event.data instanceof Object) {
          const successGettingSliderCaptcha =
            event.data["captcha_image"] != undefined &&
            event.data["captcha_slider"] != undefined;
          const reset = event.data["reset"] != undefined;
          const changeSliderStatus = event.data["slider_status"] != undefined;
          const exceedLimit =
            event.data["slider_status"] == "blocked" &&
            event.data["countdown"] != undefined &&
            event.data["expired_datetime"] != undefined;

          if (successGettingSliderCaptcha) {
            setCaptcha(event.data);
          } else if (reset) {
            onResetChallenge(event.data["reset"]);
          } else if (changeSliderStatus) {
            onSetSliderStatus(event.data["slider_status"]);

            if (exceedLimit) {
              onLockSliderCaptcha(event.data["countdown"], event.data["expired_datetime"]);
            }
          }
        }
      } catch (e) {
        window.RecaptchaFlutterChannel?.postMessage(
          JSON.stringify({ action: "log", data: e.message })
        );
      }
    };

    window.addEventListener("message", handleMessageFromFlutter);

    return () => {
      window.removeEventListener("message", handleMessageFromFlutter);
    };
  }, []);

  return (
    <div>
      <div className="header">
        <div className="title">{"ยืนยันตัวตน"}</div>
      </div>
      <div className="header">
        <div className="icon-close" onClick={() => closeCaptcha()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.6129 3.2097C4.22061 2.90468 3.65338 2.93241 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L10.5858 12L3.29289 19.2929L3.2097 19.3871C2.90468 19.7794 2.93241 20.3466 3.29289 20.7071C3.68342 21.0976 4.31658 21.0976 4.70711 20.7071L12 13.4142L19.2929 20.7071L19.3871 20.7903C19.7794 21.0953 20.3466 21.0676 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L13.4142 12L20.7071 4.70711L20.7903 4.6129C21.0953 4.22061 21.0676 3.65338 20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L12 10.5858L4.70711 3.29289L4.6129 3.2097Z"
              fill="#212121"
            />
          </svg>
        </div>
      </div>
      <div className="container-fluid">
        <div className="form-row">
          <div className="col-12">
            <div className="slidercaptcha card">
              <div className="card-header">
                <span>{text.title}</span>
                <div className="refreshIcon" onClick={refreshCaptcha}>
                  {blocked ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#E0E0E0"
                        fillRule="evenodd"
                        d="M1.667 11.802v-.135c0-.428.321-.78.736-.828l.097-.005h4.167a.833.833 0 01.097 1.66l-.097.006H4.505l1.913 1.735c1.37 1.324 3.356 1.878 5.254 1.463 1.893-.413 3.43-1.73 4.072-3.484a.833.833 0 011.565.574c-.84 2.293-2.836 4.005-5.281 4.539-2.356.514-4.818-.133-6.564-1.703l-.185-.172-1.946-1.765v2.147c0 .427-.322.78-.736.827l-.097.006a.833.833 0 01-.828-.736l-.005-.097v-4.025a.84.84 0 010-.007zM14.72 4.548l-.184-.171-.19-.165c-1.732-1.45-4.102-2.034-6.374-1.538-2.445.534-4.44 2.245-5.281 4.539a.833.833 0 001.564.574c.643-1.754 2.18-3.072 4.073-3.485 1.898-.415 3.883.14 5.254 1.463L15.494 7.5h-2.16l-.098.006a.833.833 0 00.097 1.66H17.5l.097-.005a.833.833 0 00.736-.827v-.137-4.03l-.005-.097a.834.834 0 00-.828-.736l-.097.005a.833.833 0 00-.736.828v2.146L14.72 4.548z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#838383"
                        fillRule="evenodd"
                        d="M1.667 11.802v-.135c0-.428.321-.78.736-.828l.097-.005h4.167a.833.833 0 01.097 1.66l-.097.006H4.505l1.913 1.735c1.37 1.324 3.356 1.878 5.254 1.463 1.893-.413 3.43-1.73 4.072-3.484a.833.833 0 011.565.574c-.84 2.293-2.836 4.005-5.281 4.539-2.356.514-4.818-.133-6.564-1.703l-.185-.172-1.946-1.765v2.147c0 .427-.322.78-.736.827l-.097.006a.833.833 0 01-.828-.736l-.005-.097v-4.025a.84.84 0 010-.007zM14.72 4.548l-.184-.171-.19-.165c-1.732-1.45-4.102-2.034-6.374-1.538-2.445.534-4.44 2.245-5.281 4.539a.833.833 0 001.564.574c.643-1.754 2.18-3.072 4.073-3.485 1.898-.415 3.883.14 5.254 1.463L15.494 7.5h-2.16l-.098.006a.833.833 0 00.097 1.66H17.5l.097-.005a.833.833 0 00.736-.827v-.137-4.03l-.005-.097a.834.834 0 00-.828-.736l-.097.005a.833.833 0 00-.736.828v2.146L14.72 4.548z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </div>
              </div>
              <div className="card-body">
                <Challenge
                  key={key}
                  text={text}
                  captcha={captcha}
                  loaded={!loading}
                  failed={failed}
                  blocked={blocked}
                  reset={isReset}
                  status={sliderStatus}
                  completeCaptcha={completeCaptcha}
                />
              </div>
            </div>
            {!loading && (countdown || expiredDate) && (
              <div className="scpatcha-timer">
                {"ต่อรูปใหม่ได้ในอีก   "}
                <Timer
                  timeLeftInSeconds={countdown} // TTL
                  onTimeout={onTimeout}
                />
                {"   นาที"}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
