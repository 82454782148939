import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import "./loading.css";

const Loading = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const flowCode = queryParameters.get("flowCode");
  let description = '';
  switch (flowCode) {
    case 'BUYRESERVE':
      description ='กำลังยืนยันการซื้อ-จองสลากฯ';
      break;
    case 'DURING_ROUND':
      description = 'กำลังยืนยันการชำระเงินค่าสลากฯ';
    default:
      break;
  }

  return (
    <div className="image-container">
      <div className='image-body'>
        <DotLottieReact
          className={"loading"}
          src={"/loading.lottie"}
          loop
          autoplay
        />
        <p className="wording">รอสักครู่...<br />{description}</p>
      </div>
    </div>
  );
};

export default Loading;
